import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "max-846"
};
const _hoisted_2 = {
  class: "banner-box"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "wat"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "imgs"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "rit"
};
const _hoisted_9 = {
  class: "title"
};
const _hoisted_10 = {
  class: "time"
};
import { useI18n } from "vue-i18n";
import { IMG_URL } from "@/config";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { getBanner, getNewsList, team } from "@/http";
import { router } from "@/router";
export default {
  __name: 'News',
  setup(__props) {
    const {
      t
    } = useI18n();
    const userStore = useUserStore();
    let bannerList = ref([]);
    const imgKey = computed(() => {
      return {
        'en_US': 'img_pc',
        'fr_FR': 'fr_FR_img_pc',
        'es_ES': 'es_ES_img_pc',
        'it_IT': 'it_IT_img_pc',
        'vi': 'vt_VT_img_pc'
      }[userStore.lang];
    });
    const _getBanner = () => {
      getBanner({
        category: 1
      }).then(({
        data
      }) => {
        bannerList.value = data;
      });
    };
    _getBanner();
    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1);
    const _team = async () => {
      const {
        data
      } = await getNewsList({
        page: page.value,
        perPage: 10
      });
      loading.value = false;
      finished.value = data.current_page >= data.last_page;
      if (data.current_page === 1) {
        list.value = data.data;
      } else {
        list.value = list.value.concat(data.data);
      }
      if (!finished.value) {
        page.value++;
      }
    };
    _team();
    const openUrls = item => {
      router.push(`/NewsDetails/${item.id}`);
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_text_ellipsis = _resolveComponent("van-text-ellipsis");
      const _component_van_list = _resolveComponent("van-list");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_swipe, {
        class: "banner-swipe",
        autoplay: 3000,
        "show-indicators": false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bannerList), item => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: item.id
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              class: "banner-img",
              src: _unref(IMG_URL) + item[imgKey.value],
              alt: ""
            }, null, 8, _hoisted_3)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_list, {
        loading: loading.value,
        "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value = $event),
        finished: finished.value,
        "finished-text": _ctx.$t('no_more'),
        onLoad: _team
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id,
            onClick: $event => openUrls(item),
            class: "team-item acea-row row-between-wrapper"
          }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
            src: item.picture,
            alt: ""
          }, null, 8, _hoisted_7)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_van_text_ellipsis, {
            rows: "2",
            content: item.title
          }, null, 8, ["content"])]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.created_at), 1)])], 8, _hoisted_5);
        }), 128))]),
        _: 1
      }, 8, ["loading", "finished", "finished-text"])])]);
    };
  }
};